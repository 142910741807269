export const componentItemData = [
  {
    component: 'c-add-img',
    name: '图片广告',
    props: {
      type: 0,
      boxPaddingH: 0,
      boxPaddingV: 0,
      itemMargin: 0,
      textColor: '#ffffff',
      bgColor: 'rgba(0,0,0,0.5)',
      boxBgColor: 'rgba(255,255,255,1)',
      fontSize: 12,
      children: [],
      height: 350
    }
  },
  {
    component: 'c-menu-list',
    name: '图文导航',
    props: {
      rowNum: 5,
      boxPaddingH: 10,
      boxPaddingV: 0,
      size: 100,
      radiusNum: 0,
      children: [
        {
          imgUrl: '',
          linkUrl: '',
          title: ''
        },
        {
          imgUrl: '',
          linkUrl: '',
          title: ''
        },
        {
          imgUrl: '',
          linkUrl: '',
          title: ''
        },
        {
          imgUrl: '',
          linkUrl: '',
          title: ''
        },
        {
          imgUrl: '',
          linkUrl: '',
          title: ''
        }
      ]
    }
  },
  {
    component: 'c-empty-line',
    name: '辅助分割',
    props: {
      height: 10,
      bgColor: '#f5f5f5'
    }
  },
  {
    component: 'c-text-title',
    name: '文字标题',
    props: {
      boxPaddingH: 0,
      boxPaddingV: 0,
      textColor: '#000000',
      bgColor: 'rgba(255,255,255,1)',
      fontSize: 18,
      fontWeight: 'bolder',
      textAlign: 'left',
      text: '标题'
    }
  },
  {
    component: 'c-goods-list',
    name: '商品',
    props: {
      type: 1,
      isRound: true,
      boxBgColor: '#ffffff',
      boxPaddingH: 10,
      boxPaddingV: 8,
      itemMargin: 10,
      imgScale: 0.5625, // 16:9
      showSubTitle: false,
      showOriginalPrice: false,
      showPoint: false,
      showvirtualSale: false,
      showStock: false,
      showBuyBtn: true,
      goodsType: 'single',
      goodIds: []
    }
  }
]
