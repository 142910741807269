<template>
  <div class='' id='indexPage'>
    <a-tabs v-model="activeKey" tabPosition="left" @change='changeTab'>
      <a-tab-pane :key="index" :tab="item.title" v-for='(item,index) in tabList'>
        <a-spin :spinning='tabLoading' v-if="index === 0||index === 1">
          <div>
            <a-button type='primary' @click='addNav' ghost icon='plus' style='margin-right:16px;'>添加{{activeKey===0?'banner':'导航'}}</a-button>
            <a-button type='primary' :loading="btnLoading" @click='saveSaleItemData(index)'>保存</a-button>
          </div>
          <p v-if='activeKey===0' style='margin-top:16px;'>
            banner图建议尺寸：690*270像素；可拖拽排序；
          </p>
          <p v-if='activeKey===1' style='margin-top:16px;'>
            导航图建议尺寸：88*88像素；可拖拽排序；
          </p>
          <div v-if="navList.length">
            <draggable handle=".add-input-box" :group="activeKey===0?'bannerDrag':'navDrag'" v-model="navList" :class="{'add-list':activeKey===0, 'nav-list': activeKey===1}">
              <div v-for='(item,_index) in navList' :key='_index' class='nav-item'>
                <picture-card-upload idName='indexPage' :fileList="item.saleItemImg?[item.saleItemImg]:[]" @update:fileList='res=>item.saleItemImg=res.length?res[0]:""' :max="1" />
                <div style='margin:0 24px 0 12px; width: 100%;box-sizing: border-box;' class='add-input-box'>
                  <div>
                    <div class='nav-input-box' v-if='activeKey===1'>
                      <span>导航名称：</span>
                      <a-input style='width:160px' v-model="item.saleItemTitle" placeholder='请输入'></a-input>
                    </div>

                    <div class='nav-input-box'>
                      <span>跳转地址：</span>
                      <a-select placeholder="请选择" v-model="item.jumpType" style="width: 160px" :options="jumpOpt" @change='selectJumpOpt($event,_index)'>
                      </a-select>
                    </div>
                    <div class='nav-input-box' v-if='item.jumpType === 1'>
                      <span>自定义地址：</span>
                      <a-input style='width:160px' v-model="item.customId" placeholder='请输入'></a-input>
                    </div>
                    <div class='nav-input-box' v-if='item.jumpType === 2'>
                      <span>选择分组：</span>
                      <a-select :loading='selectBoxLoading' v-model="item.customId" placeholder="请选择" style="width: 160px">
                        <a-select-option :value="item.productGroupId" :key='item.productGroupId' v-for='item in shopGroupList'>{{item.groupName}}</a-select-option>
                      </a-select>
                    </div>
                    <div class='nav-input-box' v-if='item.jumpType === 6'>
                      <span>选择商品：</span>
                      <div class='input-product-name' v-if="item.jumpLinkName && item.customId" @click='selectSingleGoodFn(_index)'>{{item.jumpLinkName}}</div>
                      <a-button v-else type='link' @click='selectSingleGoodFn(_index)'>请选择</a-button>
                    </div>
                  </div>
                  <a-icon type="delete" @click='deleteNavItem(_index)' style='font-size:20px;cursor: pointer;' theme='filled' />
                </div>

              </div>
            </draggable>
          </div>

          <div v-else class='flex-a-c' style='width:100%;200px;justify-content: center;min-200px;'>
            <a-empty description='暂无数据' />
          </div>
        </a-spin>
        <a-spin :spinning='tabLoading' v-if="index === 2 || index===3 || index===4">
          <div v-if="index===4" class='card-box'>
            <div class='card-title'>标题图</div>
            <div class='card-content-row'>
              <div>
                <a-input placeholder="标题" v-model='customTitle' style='margin-bottom:4px;'></a-input>
                <picture-card-upload idName='indexPage' :fileList="tabList[activeKey].classImg?[tabList[activeKey].classImg]:[]" @update:fileList='res=>tabList[activeKey].classImg=res.length?res[0]:""' :max="1" />
              </div>
              <a-button type='primary' style='margin-left:24px;' :loading='classImgSaveBtnLoading' @click='saveHotClassImg'>保存</a-button>
            </div>

          </div>
          <div class='card-box'>
            <div class='card-title'>商品维护</div>
            <div class='card-content'>
              <div class='flex-j-b'>
                <div>
                  <a-button type='primary' @click='selectGoods' ghost icon='plus' style='margin-right:16px;'>添加商品</a-button>
                  <a-button type='primary' :loading="btnLoading" @click='saveSaleItemData(index)'>保存</a-button>
                </div>
                <div>
                  <a-checkbox class='name' :disabled='goodList.length===0' :indeterminate='selectIds.length>0&&selectIds.length<goodList.length' @change="onSelectAll" :checked="checkAll">
                    全选
                  </a-checkbox>
                  <a-button type='primary' :disabled='selectIds.length===0' @click='deleteSelectedProducts'>移除</a-button>
                </div>
              </div>
              <div class='product-box'>
                <p style='margin-bottom:16px;' v-if="goodList.length">注：可通过拖拽排序。</p>
                <draggable group='goodListDrag' v-model="goodList" class='product-list' v-if="goodList.length">
                  <div v-for='item in goodList' :key='item.productId' style='margin-right: 16px;'>
                    <div class='product-item'>
                      <base-img :src='item.mainPicPath' class='product-img'></base-img>
                      <a-checkbox :checked="selectIds.findIndex(x=>x === item.saleClassItemId) !== -1" class='product-name el' :value="item.saleClassItemId" @change='onSelectProduct'>{{item.productName}}</a-checkbox>
                    </div>
                    <div class='un-save' v-if='item.saleClassItemId.indexOf("saleClassItemId-")!==-1'>未保存</div>
                    <div class='already-save' v-else></div>
                  </div>
                </draggable>
                <div v-else class='flex-a-c' style='width:100%;200px;justify-content: center;'>
                  <a-empty description='暂无数据' />
                </div>
              </div>
            </div>
          </div>
        </a-spin>
        <a-spin :spinning='tabLoading' v-if='index === 5'>
          <div class='group-box'>
            <div class='left-list'>
              <div class='left-title'>分组列表</div>
              <div class='list-box'>
                <div class='list' v-if="groupList.length">
                  <div class='item' :class='{active: currentId=== _item.saleClassId}' v-for='_item in groupList' :key='_item.communityGroupId' @click='clickGroupItem(_item.saleClassId)'>
                    {{_item.title}}
                  </div>
                </div>
                <a-empty description='暂无数据' style='padding-top:52px;' v-else />
              </div>
              <div class='opt-box'>
                <a-button icon='plus' type='link' @click='addSaleClass'>添加</a-button>
                <a-button icon='edit' type='link' v-if="currentId" @click='editSaleClass'>编辑</a-button>
                <a-button icon='delete' type='link' v-if="currentId">删除</a-button>
              </div>
            </div>
            <div class='card-box' style='flex:1;'>
              <div class='card-title'>商品维护</div>
              <div class='card-content'>
                <div class='right-box'>
                  <div class='flex-j-b'>
                    <div>
                      <a-button type='primary' @click='selectGoods' ghost icon='plus' style='margin-right:16px;'>添加商品</a-button>
                      <a-button type='primary' :loading="btnLoading" @click='saveSaleItemData(5)'>保存</a-button>
                    </div>
                    <div>
                      <a-checkbox class='name' :disabled='goodList.length===0' :indeterminate='selectIds.length>0&&selectIds.length<goodList.length' @change="onSelectAll" :checked="checkAll">
                        全选
                      </a-checkbox>
                      <a-button type='primary' :disabled='selectIds.length===0' @click='deleteSelectedProducts'>移除</a-button>
                    </div>
                  </div>
                  <div class='product-box'>
                    <p style='margin-bottom:16px;' v-if="goodList.length">注：可通过拖拽排序。</p>
                    <draggable :distance="1" v-model="goodList" class='product-list' v-if="goodList.length">
                      <div v-for='item in goodList' :key='item.productId' style='margin-right: 12px;'>
                        <div class='product-item'>
                          <base-img :src='item.mainPicPath' class='product-img'></base-img>
                          <a-checkbox :checked="selectIds.findIndex(x=>x === item.saleClassItemId) !== -1" class='product-name el' :value="item.saleClassItemId" @change='onSelectProduct'>{{item.productName}}</a-checkbox>
                        </div>
                        <div class='un-save' v-if='item.saleClassItemId.indexOf("saleClassItemId-")!==-1'>未保存</div>
                        <div class='already-save' v-else></div>
                      </div>
                    </draggable>
                    <div v-else class='flex-a-c' style='width:100%;200px;justify-content: center;min-200px;'>
                      <a-empty description='暂无数据' />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

        </a-spin>
      </a-tab-pane>
    </a-tabs>

    <a-modal :getContainer='getContainer' class='product-modal' width='600px' v-model="selectGoodsModal" title="选择商品" :maskClosable="false" @cancel="selectGoodsModal = false;productName = ''" @ok="selectedDone">
      <div class="search-box">
        <a-input placeholder="搜索商品名称" style="flex:1;" v-model="productName"></a-input>
        <a-button type="primary" style="margin:0 16px;" @click="getTableList">搜索</a-button>
        <a-button @click="resetSearch">重置</a-button>
      </div>
      <a-table :pagination="modalPagination" :columns="columns" :dataSource="allGoods" :rowKey="record => record.productId" :loading="selectGoodsLoading" :row-selection="{ selectedRowKeys: goodIds, onChange: onSelectChange, type: activeKey===0||activeKey===1 ? 'radio':'checkbox'}" @change="handleTableChange" class='product-table'>
        <template slot="name" slot-scope="text,record">
          <div class="table-goods-info">
            <base-img class="goods-img" :src="record.mainPicPath" width="60" height="60" />
            <a-button type="link" class="goods-name" @click="goGoodsDetail(record.productId)">{{record.productName}}</a-button>
          </div>
        </template>
      </a-table>
    </a-modal>

    <a-modal :getContainer='getContainer' v-model="visible" width="600px" :title="isAdd ? '添加分组':'编辑分组'" @ok='submit' :confirmLoading='submitLoading' @cancel='editCancel'>
      <a-form-model ref="formRef" :model="form" @submit.native.prevent :label-col="{span:4}" :wrapper-col="{span:20}" :rules="rules">
        <a-form-model-item label='分组名称' prop="title">
          <a-input v-model="form.title" type="text" placeholder="请输入分组名称"></a-input>
        </a-form-model-item>
        <a-form-model-item class='modal-upload' label='分组图片' prop="classImg" extra="建议图片尺寸：690*160像素">
          <picture-card-upload idName='indexPage' :fileList="form.classImg?[form.classImg]:[]" @update:fileList='res=>form.classImg=res.length?res[0]:""' :max="1" />
        </a-form-model-item>
        <a-form-model-item label='关联分组' prop="groupId" extra="点击分组图片跳转至对应分组">
          <a-select v-model="form.groupId" placeholder="请选择" style="width: 160px">
            <a-select-option :value="item.productGroupId" :key='item.productGroupId' v-for='item in shopGroupList'>{{item.groupName}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label='排序号' prop="seqNum">
          <a-input v-model="form.seqNum" type="text" placeholder="0~100,由大到小排序"></a-input>
        </a-form-model-item>
        <a-form-model-item label='是否启用' prop="state">
          <a-radio-group v-model='form.state'>
            <a-radio :value="0">
              是
            </a-radio>
            <a-radio :value="1">
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import draggable from 'vuedraggable'
import { onMounted, reactive, toRefs } from '@vue/composition-api'
import { shop, common } from '@/api'
const deepClone = (obj) => {
  let objClone = Array.isArray(obj) ? [] : {}
  if (obj && typeof obj === 'object') {
    for (let key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        // 判断obj子元素是否为对象，如果是，递归复制
        if (obj[key] && typeof obj[key] === 'object') {
          objClone[key] = deepClone(obj[key])
        } else {
          // 如果不是，简单复制
          objClone[key] = obj[key]
        }
      }
    }
  }
  return objClone
}
export default {
  components: {
    draggable,
  },
  setup (props, { root }) {
    const state = reactive({
      activeKey: 0,
      tabList: [],
      groupList: [],
      btnLoading: false,
      classImgSaveBtnLoading: false,
      selectBoxLoading: false,
      navList: [],
      shopGroupList: [],
      tabLoading: false,
      selectGoodsModal: false,
      selectGoodsLoading: false,
      submitLoading: false,
      checkAll: false,
      productName: '',
      visible: false,
      goodList: [],
      isAdd: false,
      currentId: '',
      selectRows: [],
      customTitle: '',
      selectSingleGood: {},
      modalPagination: {
        current: 1,
        pageSize: 5,
        total: 0,
        size: 'small',
      },
      goodIds: [],
      allGoods: [],
      selectIds: [],
      form: {
        title: '',
        classImg: '',
        seqNum: '',
        state: 0,
      },
      formRef: null,
      columns: [
        {
          title: '商品',
          dataIndex: 'name',
          scopedSlots: { customRender: 'name' },
        },
      ],
      rules: {
        title: [
          { required: true, message: '请输入分组名称', trigger: 'change' },
          { max: 16, message: '不得超过16个字' },
        ],
        classImg: [{ required: true, message: '请选择分组图片', trigger: 'change' }],
        groupId: [{ required: true, message: '请选择关联', trigger: 'change' }],
      },
      jumpOpt: [
        {
          value: 0,
          label: '不跳转',
        },
        {
          value: 1,
          label: '自定义地址',
        },
        {
          value: 2,
          label: '商品分组',
        },
        {
          value: 3,
          label: '拼团活动列表',
        },
        {
          value: 4,
          label: '砍价活动列表',
        },
        {
          value: 5,
          label: '秒杀活动列表',
        },
        {
          value: 6,
          label: '商品详情',
        },
        {
          value: 8,
          label: '直播间列表',
        },
        {
          value: 9,
          label: '我的收入',
        },
      ],
    })
    onMounted(async () => {
      await getSaleClass()
      getShopGroupList()
    })
    async function getSaleClass () {
      const { code, msg, data } = await shop.getSaleClass()
      if (code === '00000') {
        state.tabList = data
          .filter((x) => x.type !== 4)
          .concat({
            title: '商品分组',
            type: 4,
          })
        state.groupList = data.filter((x) => x.type === 4)
        if (state.activeKey === 5 && state.groupList.length) {
          state.currentId = state.groupList[0].saleClassId
        }
        getSaleClassContent()
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function getContainer () {
      return document.getElementById('indexPage')
    }
    async function getTableList () {
      state.selectGoodsLoading = true
      const { data, msg, code, page } = await shop.getChooseProducts({
        current: state.modalPagination.current,
        size: state.modalPagination.pageSize,
        communityGroupId: state.currentId,
        productName: state.productName,
        type: state.tabList[state.activeKey].type,
      })
      state.selectGoodsLoading = false
      if (code === '00000') {
        state.allGoods = data
        state.modalPagination.total = page.total
      } else {
        root.$message.error(msg || '获取商品列表失败')
      }
    }
    async function getSaleClassContent () {
      let saleClassId = ''
      if (state.activeKey !== 5) {
        saleClassId = state.tabList[state.activeKey].saleClassId
      } else {
        saleClassId = state.currentId
      }
      if (!saleClassId) return
      state.tabLoading = true
      const { code, data, msg } = await shop.getSaleClassContent({ saleClassId })
      state.tabLoading = false
      if (code === '00000') {
        if (state.activeKey === 0 || state.activeKey === 1) {
          state.navList = data.length
            ? data.map((x) => {
              return {
                ...x,
                jumpType: x.jumpLink ? Number(x.jumpLink.split('-')[0]) : undefined,
                customId: x.jumpLink ? x.jumpLink.split('-')[1] : undefined,
              }
            })
            : state.navList
        } else {
          data.forEach((x) => {
            x.productId = x.relationId
          })
          state.goodList = deepClone(data)
          state.selectRows = deepClone(data)
          state.goodIds = deepClone(data).map((x) => x.relationId)
        }
        console.log(state.navList)
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function onSelectAll (e) {
      state.checkAll = e.target.checked
      if (state.checkAll) {
        // 去重 合并
        state.selectIds = Array.from(
          new Set(state.goodList.map((x) => x.saleClassItemId).concat(state.selectIds))
        )
      } else {
        state.selectIds = state.selectIds.filter(
          (x) => state.goodList.map((z) => z.saleClassItemId).indexOf(x) === -1
        )
      }
    }
    async function saveHotClassImg () {
      let param = deepClone(state.tabList[state.activeKey])
      console.log(param)
      state.classImgSaveBtnLoading = true
      const { msg, code } = await shop.saveSaleClass(
        Object.assign(param, { title: state.customTitle })
      )
      state.classImgSaveBtnLoading = false
      if (code === '00000') {
        root.$message.success('保存成功')
        getSaleClass()
      } else {
        root.$message.error(msg || '保存失败')
      }
    }
    async function saveSaleItemData (index) {
      let param = null
      if (index === 0 || index === 1) {
        console.log(state.navList)
        param = state.navList.map((x, _index) => {
          let jumpLink = ''
          if (x.jumpType === 0) {
            jumpLink = 0
          } else if (x.jumpType !== undefined) {
            jumpLink = x.jumpType + '-' + (x.customId ? x.customId.replace(/-/g, 'x/f/x') : '')
          }
          return {
            ...x,
            jumpLink,
            seqNum: state.navList.length - _index - 1,
          }
        })
        if (state.navList.length) {
          let errorIndex = state.navList.findIndex(
            (x) =>
              !x.saleItemImg ||
              (state.activeKey === 1 && !x.saleItemTitle) ||
              ((x.jumpType === 2 || x.jumpType === 6) && !x.customId)
          )
          if (errorIndex !== -1) {
            return root.$message.error(`第${errorIndex + 1}条数据未填写完整`)
          }
        }
      } else {
        param = state.goodList.map((x, _index) => {
          return {
            relationId: x.productId,
            seqNum: state.goodList.length - _index - 1,
            saleClassItemId:
              x.saleClassItemId.indexOf('saleClassItemId-') === -1 ? x.saleClassItemId : '',
            saleClassId: index !== 5 ? state.tabList[state.activeKey].saleClassId : state.currentId,
          }
        })
      }
      state.btnLoading = true
      const { code, msg } = await shop.saveSaleClassContent(param)
      state.btnLoading = false
      if (code === '00000') {
        root.$message.success('保存成功')
        state.selectIds = []
        state.checkAll = false
        state.goodIds = []
        state.selectRows = []
        state.selectSingleGood = {}
        getSaleClassContent()
      } else {
        root.$message.error(msg || '保存失败')
      }
    }
    function addNav () {
      state.navList.push({
        saleItemImg: '',
        saleItemTitle: '',
        seqNum: '',
        jumpType: undefined,
        customId: undefined,
        saleClassId: state.tabList[state.activeKey].saleClassId,
      })
    }
    function deleteNavItem (index) {
      console.log()
      root.$confirm({
        title: '删除提示',
        content: '确定要删除？',
        onOk: async () => {
          const { code, msg } = await shop.removeProduct({
            saleClassItemIds: state.navList[index].saleClassItemId,
          })
          if (code === '00000') {
            root.$message.success('移除成功！')
            state.selectIds = []
            state.checkAll = false
            getSaleClassContent()
          } else {
            root.$message.error(msg || '移除失败！')
          }
        },
      })
    }
    async function selectedDone () {
      if (state.activeKey === 0 || state.activeKey === 1) {
        state.navList[state.currentNavIndex].customId = state.selectSingleGood.productId
        state.navList[state.currentNavIndex].jumpLinkName = state.selectSingleGood.productName
      } else {
        state.goodList = state.selectRows.filter((x) => state.goodIds.indexOf(x.productId) !== -1)
      }
      state.selectGoodsModal = false
    }
    function resetSearch () {
      state.productName = ''
      getTableList()
    }
    function onSelectChange (selectedRowKeys, selectRows) {
      if (state.activeKey === 0 || state.activeKey === 1) {
        state.selectSingleGood = selectRows[0]
      } else {
        let alreadyIds = state.selectRows.map((x) => x.productId)
        selectRows.forEach((x, i) => {
          if (alreadyIds.indexOf(x.productId) === -1) {
            state.selectRows.push({
              ...x,
              saleClassItemId: 'saleClassItemId-' + i,
            })
          }
        })
      }
      state.goodIds = selectedRowKeys
    }
    function handleTableChange ({ current }) {
      state.modalPagination.current = current
      getTableList()
    }
    function selectSingleGoodFn (index) {
      state.currentNavIndex = index
      selectGoods()
    }
    function selectGoods () {
      state.selectGoodsModal = true
      getTableList()
    }
    function goGoodsDetail (id) {
      root.$router.push(`/goods/goods_detail/${id}`)
    }
    function onSelectProduct (e) {
      const checked = e.target.checked
      const value = e.target.value
      if (checked) {
        if (state.selectIds.indexOf(value) === -1) {
          state.selectIds.push(value)
        }
      } else {
        const index = state.selectIds.findIndex((x) => x === value)
        state.selectIds.splice(index, 1)
      }
    }
    function changeTab () {
      state.goodList = []
      state.navList = []
      state.goodIds = []
      if (state.activeKey === 5) {
        if (state.groupList.length) {
          state.currentId = state.groupList[0].saleClassId
        }
      }
      if (state.activeKey === 4) {
        state.customTitle = state.tabList[state.activeKey].title
      }
      console.log(state.currentId)
      getSaleClassContent()
    }
    async function deleteSelectedProducts () {
      if (
        state.goodList.findIndex((x) => x.saleClassItemId.indexOf('saleClassItemId-') !== -1) !== -1
      ) {
        return root.$message.warning('含有未保存的商品，请先保存！')
      }
      root.$confirm({
        title: '提示',
        content: `确定要移除选中的${state.selectIds.length}个商品？`,
        onOk: async () => {
          let ids = state.selectIds.filter((x) => x.indexOf('saleClassItemId-') === -1)
          const { code, msg } = await shop.removeProduct({
            saleClassItemIds: ids.join(','),
          })
          if (code === '00000') {
            root.$message.success('移除成功！')
            state.selectIds = []
            state.checkAll = false
            getSaleClassContent()
          } else {
            root.$message.error(msg || '移除失败！')
          }
        },
      })
    }
    async function submit () {
      state.formRef.validate(async (valid) => {
        if (valid) {
          state.submitLoading = true
          const { data, msg, code } = await shop.saveSaleClass(
            Object.assign(state.form, { type: 4 })
          )
          state.submitLoading = false
          if (code === '00000') {
            root.$message.success(state.isAdd ? '添加成功' : '修改成功')
            state.visible = false
            editCancel()
            // getList()
            getSaleClass()
          } else {
            root.$message.error(msg || '操作失败')
          }
        }
      })
    }
    async function editCancel () {
      state.formRef.resetFields()
      state.form = {
        title: '',
        classImg: '',
        seqNum: '',
        state: true,
      }
    }
    function addSaleClass () {
      state.isAdd = true
      state.visible = true
    }
    function clickGroupItem (id) {
      state.currentId = id
      getSaleClassContent()
    }
    async function editSaleClass () {
      state.isAdd = false
      state.visible = true
      state.form = deepClone(state.groupList.find((x) => x.saleClassId === state.currentId))
      await root.$nextTick()
      state.formRef.clearValidate()
    }
    async function getShopGroupList () {
      state.selectBoxLoading = true
      let { code, msg, data } = await common.getProductGroupByShopId()
      state.selectBoxLoading = false

      if (code === '00000') {
        state.shopGroupList = data
      } else {
        root.$message.error(msg || '获取分组数据失败')
      }
    }
    function selectJumpOpt (e, index) {
      root.$set(state.navList[index], 'customId', undefined)
      state.goodIds = []
      state.selectSingleGood = {}
    }
    return {
      selectSingleGoodFn,
      selectJumpOpt,
      getShopGroupList,
      editCancel,
      editSaleClass,
      submit,
      changeTab,
      onSelectChange,
      getTableList,
      getContainer,
      addNav,
      saveSaleItemData,
      getSaleClassContent,
      getSaleClass,
      addSaleClass,
      deleteNavItem,
      saveHotClassImg,
      resetSearch,
      handleTableChange,
      selectedDone,
      selectGoods,
      ...toRefs(state),
      goGoodsDetail,
      deleteSelectedProducts,
      onSelectProduct,
      onSelectAll,
      clickGroupItem,
    }
  },
}
</script>
<style lang="less" scoped>
.nav-list {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .nav-item {
    display: flex;
    align-items: center;
    width: 420px;
    margin-bottom: 16px;
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    margin-right: 16px;
    ::v-deep .ant-upload-list-item {
      margin-bottom: 0;
    }
    ::v-deep .upload-box {
      margin-right: 8px;
    }
    .nav-input-box {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .input-product-name {
      padding: 0;
      width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
      cursor: pointer;
      color: #1890ff;
    }
  }
}
.modal-upload {
  ::v-deep .ant-upload-list-item {
    width: 345px;
    height: 80px;
    margin: 0 0 8px 0;
    box-sizing: content-box;
  }
  ::v-deep .upload-box {
    width: 345px;
    margin: 0 0 8px 0;
    height: 80px;
    padding: 8px;
  }
}
.add-list {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .nav-item {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    width: 369px;
    margin-bottom: 16px;
    background: #fff;
    border-radius: 8px;
    padding: 12px;
    margin-right: 16px;
    ::v-deep .ant-upload-list-item {
      width: 345px;
      height: 135px;
      margin: 0 0 8px 0;
    }
    ::v-deep .upload-box {
      width: 345px;
      margin: 0 0 8px 0;
      height: 135px;
      padding: 8px;
    }
    .nav-input-box {
      margin-bottom: 8px;
      display: flex;
      align-items: center;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .input-product-name {
      padding: 0;
      width: 160px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      text-align: left;
      cursor: pointer;
      color: #1890ff;
    }
  }
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 32px;
}

.product-modal ::v-deep .ant-modal-body {
  padding: 0 24px 12px;
}
.table-goods-info {
  display: flex;
  align-items: center;
  .goods-img {
    width: 60px;
  }
  .goods-name {
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
}
.product-table ::v-deep td {
  padding: 4px 12px;
}
.select-group-list {
  max-height: 400px;
  overflow: auto;
}
.select-group-item {
  height: 44px;
  border-bottom: 1px solid #eee;
  cursor: pointer;
  .active {
    color: @main-color;
  }
  &.active {
    color: @main-color;
  }
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: @main-color;
  }
}
.search-box {
  display: flex;
  align-items: center;
  margin: 12px 16px;
  justify-content: flex-end;
}
.good-list {
  margin-top: 16px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  .good-item {
    display: flex;
    align-items: center;
    width: 380px;
    margin-bottom: 16px;
  }
}

.product-box {
  margin-top: 16px;
  .product-list {
    display: flex;
    flex-wrap: wrap;
    .product-item {
      cursor: pointer;
      width: 160px;
      background: #fff;
      border-radius: 6px;
      box-shadow: 0 4px 4px #ddd;
      overflow: hidden;
      &:nth-of-type(7n) {
        margin-right: 0;
      }
      .product-img {
        width: 100%;
        display: block;
        margin: 0 auto;
        ::v-deep img {
          height: 120px;
          object-fit: cover;
        }
      }
      .product-name {
        width: 100%;
        margin: 0 auto;
        line-height: 16px;
        padding: 4px;
        display: block;
      }
    }
  }
}
.flex-j-b {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.un-save {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #ff4d4f;
  margin-bottom: 16px;
  margin-top: 6px;
}
.already-save {
  width: 100%;
  text-align: center;
  font-size: 12px;
  color: #52c41a;
  margin-bottom: 16px;
  margin-top: 6px;
}
.left-list {
  margin-right: 16px;
  width: 260px;
  background: #fff;
  height: 100%;
  box-sizing: border-box;
  flex-shrink: 0;
  border-radius: 8px;
  box-shadow: 0 4px 8px #ddd;
  .left-title {
    line-height: 44px;
    font-size: 16px;
    font-weight: 900;
    padding: 0 16px;
    text-align: center;
    border-bottom: 1px solid #ddd;
    color: #000;
  }
  .list-box {
    box-sizing: border-box;
    min-height: 360px;
    overflow: auto;
    .list {
      height: 100%;
      width: 100%;
      overflow: auto;
      .item {
        padding: 12px 16px;
        cursor: pointer;
        &:hover {
          color: #1890ff !important;
        }
        &.active {
          color: #1890ff !important;
        }
      }
    }
  }
  .opt-box {
    display: flex;
    align-items: center;
    justify-content: space-around;
    border-top: 1px solid #ddd;
    height: 48px;
    margin-top: 32px;
    button {
      display: block;
    }
  }
}
.group-box {
  display: flex;
  .right-box {
    flex: 1;
    min-height: 360px;
  }
}
.flex-a-c {
  display: flex;
  align-items: center;
}
.add-input-box {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0 !important;
  min-height: 72px;
  cursor: move;
}
.card-box {
  padding: 0 16px;
  border-radius: 8px;
  box-shadow: 0px 40x 8px #ddd;
  background: #fff;
  margin-bottom: 16px;
  .card-title {
    line-height: 44px;
    font-weight: 900;
    border-bottom: 1px solid #ddd;
    font-size: 16px;
    color: #000;
  }
  ::v-deep .ant-upload-list-item {
    width: 690px;
    height: 80px;
    margin: 0;
  }
  ::v-deep .upload-box {
    width: 690px;
    height: 80px;
    margin: 0;
  }
  .card-content-row {
    display: flex;
    align-items: center;
    padding: 12px 0;
  }
  .card-content {
    padding: 12px 0;
  }
}
</style>
