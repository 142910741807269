<template>
  <div>
    <IndexGoodsManangement v-if='isPlatFormShop'></IndexGoodsManangement>
    <Renovation v-else></Renovation>
  </div>
</template>

<script>
import { SHOPID } from '@/constants'
import { getSession } from '@/utils/session'
import { ref } from '@vue/composition-api'
import Renovation from './PageRenovation'
import IndexGoodsManangement from './PageIndexGoodsManangement'
export default {
  components: {
    Renovation,
    IndexGoodsManangement
  },
  setup () {
    const isPlatFormShop = ref(false)
    if (process.env.VUE_APP_PLATFORMSHOPID === getSession(SHOPID)) {
      isPlatFormShop.value = true
    } else {
      isPlatFormShop.value = false
    }
    return {
      isPlatFormShop
    }
  }
}
</script>

<style>

</style>
