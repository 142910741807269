<template>
  <div style='min-width: 1000px;' id='page-box'>

    <div class="main-page">
      <div class="page-left">
        <a-collapse style='height:100%;overflow:auto;' v-model="activeKey" :bordered="false">
          <a-collapse-panel key="1" class="template-list">
            <span slot="header" class='costom-header'>
              <span>我的模版</span>
              <a-icon style='margin-left: 12px;' type="question-circle" @click='templateIntro' />
            </span>
            <a-button slot="extra" type='link' @click='openModal($event)' style='font-size:14px;line-height:14px;height:14px;padding:0;'>添加</a-button>
            <div v-for='(temp,index) in myTemplateList' :key='temp.shopDesignTemplateId' class='template-item' :class='{active: temp.shopDesignStorageId===currentTemplateId}' @click='changeTemplate($event,temp,"my")'>
              <a-popover trigger='hover' placement='right'>
                <template slot="content">
                  <a-button icon='edit' type='link' @click='openModal($event,temp)' :disabled='!currentTemplateId || myTemplateList.map(x=>x.shopDesignStorageId).indexOf(currentTemplateId) === -1'></a-button>
                  <a-button @click='deleteTemplate(temp)' icon='delete' type='link' :disabled='!currentTemplateId || myTemplateList.map(x=>x.shopDesignStorageId).indexOf(currentTemplateId) === -1'></a-button>
                </template>
                <span>{{temp.title||'未命名'+(index+1)}} <b v-if="temp.firstPageFlag" style='font-size:12px;'>(首页)</b></span>
              </a-popover>
            </div>
            <a-empty v-if='myTemplateList.length === 0' description='暂无模版' />
          </a-collapse-panel>
          <a-collapse-panel key="3" header="官方模版" class="template-list">
            <!-- <a-icon slot="extra" type="question-circle" @click='templateIntro' /> -->
            <div v-for='temp in officeTemplateList' :key='temp.shopDesignTemplateId' class='template-item' :class='{active: temp.shopDesignTemplateId===currentTemplateId}' @click='changeTemplate($event,temp,"office")'>{{temp.title}}</div>
            <a-empty v-if='officeTemplateList.length === 0' description='暂无模版' />
          </a-collapse-panel>
          <a-collapse-panel key="2" header="基础模块">
            <div class="component-list">
              <draggable class="menu-draggable" :list="leftMenu" style="display:flex;flex-wrap:wrap" :group="{ name: 'components', pull: 'clone', put: false}" draggable=".component-item">
                <div class="component-item" :class="[menu.component]" v-for="(menu,index) in leftMenu" :key="index">
                  <p class="component-name">{{menu.name}}</p>
                </div>
              </draggable>
            </div>

            <p style='font-size:12px;margin-top:8px;'>提示：模块可直接拖拽至右侧更加便捷</p>
          </a-collapse-panel>
        </a-collapse>
        <div class='btn-box'>
          <a-button type="primary" @click="release" :loading='releaseLoading' style='margin-right: 24px'>上架</a-button>
          <a-button @click="submit" :loading='saveLoading'>保存并继续</a-button>
        </div>
      </div>
      <div class="page-content">
        <div class="phone-box">
          <p class="line">iphone8 手机高度</p>
          <div class="phone-status-bar">
            <div class="status-left">
              <div class="dote-icon-list">
                <div></div>
                <div></div>
                <div></div>
                <div></div>
                <div></div>
              </div>
              <icon-svg icon-class="icon-wifi" class="status-icon" />
            </div>
            <div>{{nowDate}}</div>
            <div class="status-right">
              <div>100%</div>
              <icon-svg icon-class="icon-dianchi" class="status-icon" />
            </div>
          </div>
          <div class="phone-title">
            {{$store.getters['head/getShopInfo'].name}}
            <icon-svg icon-class="icon-phone-more" class="status-icon" />
          </div>
          <draggable v-model="data" class="phone-draggable" style="min-height:599px;background:#f1f1f1" group="components" @change="addComponentDone">
            <template v-if='data.length'>
              <div v-for="(item,index) in data" :key="index" class="component-box">
                <div class="line-tag">模块{{index + 1}}</div>
                <component class="component" @click.native.stop="activeIndex===index?activeIndex = null:activeIndex = index" :is="item.component" :props="item.props" :goodsObject="goodsList.find(value=>Number(value.seqNum) ===item.seqNum)?goodsList.find(value=>Number(value.seqNum) ===item.seqNum):{}" :class="{ 'c-active': activeIndex === index }"></component>
                <a-icon type="close-circle" v-if="activeIndex === index" class="c-close-icon" @click="deleteComponent(index)" />
              </div>
            </template>
            <div v-if='data.length === 0 && templateIndex === ""' class='no-design-data'>
              <div class='no-design-data-text'>
                <p class='h3'>快来装修您的店铺吧！</p>
                <p class='p'>在左侧选择模版，或者将需要的模块拖进来，自己就可以装出想要的风格，赶快行动吧 ^_^</p>
              </div>
              <img src="@/assets/people.png" alt="" class='no-design-data-img'>
            </div>
          </draggable>
        </div>
      </div>
      <a-drawer :getContainer='getContainer' v-if="activeIndex !==null" :title="data[activeIndex].name" width='420' :mask='false' placement="right" :closable="true" :visible="activeIndex !== null" @close="onCloseDrawer">
        <div class="page-right">
          <component :is="data[activeIndex].component + '-operation'" :props="data[activeIndex].props" :goodsObject="goodsList.find(val=>Number(val.seqNum) ===data[activeIndex].seqNum)?goodsList.find(val=>Number(val.seqNum) === data[activeIndex].seqNum):{}" @update:goodsList='updateGoodsList' />
        </div>
      </a-drawer>

    </div>
    <a-modal v-model="showError" @ok='showError = false'>
      <div slot='title' style='display:flex;align-items: center;height:30px'>
        <a-icon type="exclamation-circle" style='color:red;font-size: 24px;margin-right:8px;' />
        <div>错误提示</div>
      </div>
      <div style='line-height:32px;font-size:14px;color:#333;' v-for='(item,index) in errorText' :key="index" v-html='item'></div>
    </a-modal>
    <a-modal v-model="showTemplateIntro" cancelText='知道了' okText='不再提醒' title='装修模版使用须知' @ok='noRemind' :width='600'>
      <p style='line-height:32px;font-size:14px;color:#333;'>
        1、“我的模版”中是您自定义装修或使用其他模版保存后的数据。
      </p>
      <p style='line-height:32px;font-size:14px;color:#333;'>
        2、“官方模版”只提供布局方案，其内容包含的商品、商品分组、跳转链接等模版数据需您自行替换。您也可以在此基础上修改或添加任意基础模块。
      </p>
      <p style='line-height:32px;font-size:14px;color:red;'>
        3、“图文导航”可配置跳转到自定义装修页面，目标页面需要先上架才能使用。
      </p>
    </a-modal>

    <a-modal :zIndex='3000' v-model="showForm" :title='isAdd?"创建模版":"编辑模版"' :confirmLoading='templateFormLoading' @ok='handleSaveTemplate'>
      <a-form-model :label-col="{ span: 4 }" :wrapper-col="{ span: 16 }" ref='templateForm' @submit.native.prevent :model="formData" :rules='rules'>
        <a-form-model-item label="模版名" prop='title'>
          <a-input v-model="formData.title" placeholder="模版名称">
          </a-input>
        </a-form-model-item>
      </a-form-model>
    </a-modal>

    <a-modal v-model="releaseModalShow" title='上架确认' :confirmLoading='releaseLoading' @ok='releaseConfirm'>
      <a-form-model :label-col="{ span: 5 }" :wrapper-col="{ span: 18 }" ref='releaseFormRef' :rules='rules' @submit.native.prevent :model="releaseForm">
        <a-form-model-item label="模版名称" prop='title' v-if="currentTemplateData.hasOwnProperty('shopDesignTemplateId')">
          <a-input v-model="releaseForm.title" placeholder="模版名称">
          </a-input>
        </a-form-model-item>
        <a-form-model-item label="是否设为首页" prop='firstPageFlag' extra='首页只能上架一个，新设为首页的模版将替换之前的。'>
          <a-radio-group name="radioGroup" v-model='releaseForm.firstPageFlag'>
            <a-radio :value="true">
              是
            </a-radio>
            <a-radio :value="false">
              否
            </a-radio>
          </a-radio-group>
        </a-form-model-item>
      </a-form-model>
    </a-modal>
  </div>
</template>
<script>
import { SHOPID, NOTEMPLATEINTROFLAG } from '@/constants'
import { getSession, setSession } from '@/utils/session'
import { shop } from '@/api'
import draggable from 'vuedraggable'
import moment from 'moment'
import { reactive, toRefs, onUnmounted, onMounted, ref } from '@vue/composition-api'
import { componentItemData, templateData } from './templateStaticData'
moment.locale('en')
export default {
  name: 'PageRenovation',
  components: {
    draggable,
  },
  setup (props, { root }) {
    const templateForm = ref(null)
    const releaseFormRef = ref(null)
    const state = reactive({
      myTemplate: [],
      enable: 'enable',
      templateIndex: '',
      saveLoading: false,
      releaseLoading: false,
      goodsList: [],
      activeKey: ['1', '2', '3'],
      activeIndex: null,
      leftMenu: componentItemData,
      data: [],
      interval: null,
      showError: false,
      showTemplateIntro: false,
      errorText: [],
      nowDate: moment().format('hh:mm A'),
      releaseForm: {
        title: '',
        firstPageFlag: false,
      },
      myTemplateList: [],
      officeTemplateList: [],
      currentTemplateId: '',
      showForm: false,
      templateFormLoading: false,
      releaseModalShow: false,
      formData: {},
      isAdd: false,
      currentTemplateData: {},
      rules: {
        title: [
          { required: true, message: '请输入模版名', trigger: 'change' },
          { max: 16, message: '最多16位', trigger: 'change' },
        ],
      },
    })
    function upDateNowDate () {
      state.interval = setInterval(() => {
        state.nowDate = moment().format('hh:mm A')
      }, 1000)
    }
    onMounted(async () => {
      upDateNowDate()
      await getList()
    })
    onUnmounted(() => {
      clearInterval(state.interval)
    })
    function addComponentDone (e) {
      if (e.added) {
        e.added.element.seqNum = new Date().getTime()
        state.activeIndex = e.added.newIndex
        if (state.data.length === 1 && e.added.newIndex === 1) {
          state.activeIndex = 0
        }
        if (e.added.element.component === 'c-goods-list') {
          state.goodsList.push({
            seqNum: e.added.element.seqNum,
            goodsList: [],
          })
        }
      }
      if (e.moved) {
        state.activeIndex = e.moved.newIndex
      }
      state.data = deepClone(state.data)
    }
    function deepClone (obj) {
      let objClone = Array.isArray(obj) ? [] : {}
      if (obj && typeof obj === 'object') {
        for (let key in obj) {
          if (obj.hasOwnProperty(key)) {
            // 判断obj子元素是否为对象，如果是，递归复制
            if (obj[key] && typeof obj[key] === 'object') {
              objClone[key] = deepClone(obj[key])
            } else {
              // 如果不是，简单复制
              objClone[key] = obj[key]
            }
          }
        }
      }
      return objClone
    }
    function deleteComponent (index) {
      state.data.splice(index, 1)
      if (index === state.activeIndex) {
        state.activeIndex = null
      }
    }
    async function submit () {
      if (!checkTemplateGoods()) return
      if (state.currentTemplateData.hasOwnProperty('shopDesignTemplateId')) {
        state.isAdd = true
        openModal()
      } else {
        let param = {
          shopId: getSession(SHOPID),
          designData: JSON.stringify(state.data),
          shopDesignStorageId: state.currentTemplateId,
        }
        state.saveLoading = true
        const { code, msg } = await shop.saveTemplate(param)
        state.saveLoading = false
        if (code === '00000') {
          root.$message.success('保存成功')
          getList()
        } else {
          root.$message.error(msg || '保存失败')
        }
      }
    }
    async function releaseConfirm () {
      let param = Object.assign(state.currentTemplateData, {
        designData: JSON.stringify(state.data),
        ...state.releaseForm,
        shopId: getSession(SHOPID),
      })
      state.releaseLoading = true
      let { code, msg } = await shop.releaseShopDesign(param)
      state.releaseLoading = false
      state.releaseModalShow = false
      if (code === '00000') {
        if (releaseFormRef.value) {
          releaseFormRef.value.resetFields()
        }
        root.$message.success('上架成功')
        getList()
      } else {
        root.$message.error(msg || '上架失败')
      }
    }
    async function release () {
      if (!checkTemplateGoods()) return
      if (!state.currentTemplateData.hasOwnProperty('shopDesignTemplateId')) {
        state.releaseForm.title = state.currentTemplateData.title
      }
      state.releaseForm.firstPageFlag = state.currentTemplateData.firstPageFlag
      if (!state.currentTemplateData.firstPageFlag) {
        state.releaseModalShow = true
      } else {
        this.$confirm({
          title: '上架操作提示',
          content: '确定要上架到首页吗，此操作将同步至小程序',
          okText: '确定',
          cancelText: '取消',
          onOk: () => {
            releaseConfirm()
          },
        })
      }
    }
    function checkTemplateGoods () {
      state.errorText = []
      if (state.data.length === 0) {
        state.errorText.push(`<p>您还未装修任何内容</p>`)
      }
      state.data.forEach((item, index) => {
        if (item.component === 'c-goods-list') {
          if (item.props.goodsType === 'single') {
            if (item.props.goodIds.length === 0) {
              state.errorText.push(
                `<p>【模块${
                  index + 1
                }】内<span style='color:red;'>未选择商品</span>，请移除该模块或添加商品!</p>`
              )
            }
            if (item.props.goodIds.findIndex((x) => x.indexOf('xfxproductid') !== -1) !== -1) {
              state.errorText.push(
                `<p>【模块${
                  index + 1
                }】内<span style='color:red;'>含有“模版商品”</span>，请移除或修改！</p>`
              )
            }
          } else {
            if (item.props.groupId === undefined) {
              state.errorText.push(
                `<p>【模块${
                  index + 1
                }】内<span style='color:red;'>未选择商品分组</span>，请移除该模块或添加商品分组!</p>`
              )
            }
            if (item.props.groupId.indexOf('templateGroup') !== -1) {
              state.errorText.push(
                `<p>【模块${
                  index + 1
                }】内<span style='color:red;'>含有“商品分组模版”</span>，请移除或修改！</p>`
              )
            }
          }
        } else if (item.component === 'c-add-img') {
          if (item.props.children.length) {
            item.props.children.forEach((x) => {
              if (x.linkType === 6 && x.id.indexOf('xfxproductid') !== -1) {
                state.errorText.push(
                  `<p>【模块${
                    index + 1
                  }】内<span style='color:red;'>含有“跳转模版商品详情链接”</span>，请移除或修改！</p>`
                )
              }
              if (x.linkType === 2 && x.groupId.indexOf('templateGroup') !== -1) {
                state.errorText.push(
                  `<p>【模块${
                    index + 1
                  }】内<span style='color:red;'>含有“跳转模版商品分组链接”</span>，请移除或修改！</p>`
                )
              }
            })
          }
        } else if (item.component === 'c-menu-list') {
          if (item.props.children.length) {
            item.props.children.forEach((x) => {
              if (x.linkType === 6 && x.id.indexOf('xfxproductid') !== -1) {
                state.errorText.push(
                  `<p>【模块${
                    index + 1
                  }】内<span style='color:red;'>含有“跳转模版商品详情链接”</span>，请移除或修改！</p>`
                )
              }
              if (x.linkType === 2 && x.groupId.indexOf('templateGroup') !== -1) {
                state.errorText.push(
                  `<p>【模块${
                    index + 1
                  }】内<span style='color:red;'>含有“跳转模版商品分组链接”</span>，请移除或修改！</p>`
                )
              }
              if (
                x.linkType === 7 &&
                state.officeTemplateList.find((a) => a.shopDesignTemplateId === x.templateId)
              ) {
                state.errorText.push(
                  `<p>【模块${
                    index + 1
                  }】内<span style='color:red;'>含有“跳转模版页面链接”</span>，请移除或修改！</p>`
                )
              }
            })
          }
        }
      })
      if (state.errorText.length) {
        state.showError = true
        return false
      } else {
        return true
      }
    }
    async function getList () {
      let { code, msg, data } = await shop.getDesignList({
        shopId: getSession(SHOPID),
      })
      if (code === '00000') {
        state.myTemplateList = data.shopDesignStorages
        state.officeTemplateList = data.shopDesignTemplates
        if (!state.currentTemplateId) {
          if (data.shopDesignStorages.length && data.shopDesignStorages[0].designData) {
            state.currentTemplateId = data.shopDesignStorages[0].shopDesignStorageId
            state.currentTemplateData = data.shopDesignStorages[0]
            getTemplateDataById(data.shopDesignStorages[0].shopDesignStorageId)
          } else if (data.shopDesignTemplates.length && data.shopDesignTemplates[0].designData) {
            state.currentTemplateId = data.shopDesignTemplates[0].shopDesignTemplateId
            const temp = data.shopDesignTemplates[0]
            const obj = JSON.parse(temp.designData)
            let leftMenuData = deepClone(componentItemData)
            if (obj.goodsList) {
              state.goodsList = JSON.parse(obj.goodsList) || []
            }
            if (obj.designData) {
              state.data = JSON.parse(obj.designData) || []
              state.data.forEach((item) => {
                let leftMenuDataProps = leftMenuData.find(
                  (temp) => temp.component === item.component
                ).props
                let props = item.props
                item.props = Object.assign(deepClone(leftMenuDataProps), deepClone(props))
              })
            }
          } else {
            state.data = []
            state.goodsList = []
          }
        }
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    async function getTemplateDataById (id) {
      const { code, msg, data } = await shop.getDesignDetail({
        shopId: getSession(SHOPID),
        shopDesignStorageId: id,
      })
      if (code === '00000') {
        if (data.designStorage.designData) {
          let leftMenuData = deepClone(state.leftMenu)
          state.data = JSON.parse(data.designStorage.designData)
          state.goodsList = data.goodList
          state.data.forEach((item) => {
            let leftMenuDataProps = leftMenuData.find((temp) => temp.component === item.component)
              .props
            let props = item.props
            item.props = Object.assign(deepClone(leftMenuDataProps), deepClone(props))
          })
        } else {
          state.data = []
          state.goodsList = []
        }
      } else {
        root.$message.error(msg || '获取数据失败')
      }
    }
    function updateGoodsList (res) {
      root.$set(
        state,
        'goodsList',
        state.goodsList.map((item) => {
          if (item.seqNum === res.seqNum) {
            item.goodsList = res.goodsList
          }
          return item
        })
      )
    }
    function changeTemplate (e, temp, type) {
      onCloseDrawer()
      state.currentTemplateData = temp
      state.currentTemplateId = type === 'my' ? temp.shopDesignStorageId : temp.shopDesignTemplateId
      if (type === 'my') {
        getTemplateDataById(temp.shopDesignStorageId)
      } else {
        if (temp.designData) {
          const obj = JSON.parse(temp.designData)
          let leftMenuData = deepClone(componentItemData)

          if (obj.goodsList) {
            state.goodsList = JSON.parse(obj.goodsList) || []
          }
          if (obj.designData) {
            state.data = JSON.parse(obj.designData) || []
            state.data.forEach((item) => {
              let leftMenuDataProps = leftMenuData.find((temp) => temp.component === item.component)
                .props
              let props = item.props
              item.props = Object.assign(deepClone(leftMenuDataProps), deepClone(props))
            })
          }
        } else {
          state.goodsList = []
          state.data = []
        }
      }
    }
    function templateIntro (event) {
      event.stopPropagation()
      state.showTemplateIntro = true
    }
    function onCloseDrawer () {
      state.activeIndex = null
    }
    function getContainer () {
      return document.getElementById('page-box')
    }
    function noRemind () {
      state.showTemplateIntro = false
      setSession(NOTEMPLATEINTROFLAG, true)
    }

    async function openModal (e, temp) {
      if (e) {
        e.stopPropagation()
      }
      if (temp) {
        state.currentTemplateData = temp
        state.isAdd = false
      } else {
        state.isAdd = true
      }
      state.showForm = true
      await root.$nextTick()
      templateForm.value.clearValidate()
      if (!state.isAdd) {
        state.formData = state.currentTemplateData
      } else {
        state.formData = {}
      }
    }

    async function handleSaveTemplate () {
      /**
       * 1、创建我的模版
       * 2、编辑我的模版
       * 3、将 官方模版 暂存
       */
      templateForm.value.validate(async (valid) => {
        if (valid) {
          state.templateFormLoading = true
          let param = {
            ...state.formData,
            shopId: getSession(SHOPID),
          }
          if (state.currentTemplateData.hasOwnProperty('shopDesignTemplateId')) {
            param.designData = state.data
          }

          const { code, msg } = state.isAdd
            ? await shop.saveTemplate(param)
            : await shop.releaseShopDesign(Object.assign(state.currentTemplateData, param))
          state.templateFormLoading = false
          if (code === '00000') {
            root.$message.success('创建成功')
            state.showForm = false
            state.isAdd = false
            getList()
          } else {
            root.$message.error(msg || '创建失败')
          }
        }
      })
    }

    function deleteTemplate (temp) {
      state.currentTemplateData = temp
      root.$confirm({
        title: '删除提示',
        content: `确定要删除模版【${state.currentTemplateData.title}】`,
        okText: '删除',
        cancelText: '取消',
        onOk: async () => {
          const { code, msg } = await shop.deleteTemplate({
            shopDesignStorageId: temp.shopDesignStorageId,
          })
          if (code === '00000') {
            state.currentTemplateData = {}
            root.$message.success('删除成功')
            getList()
          } else {
            root.$message.error(msg || '删除失败')
          }
        },
      })
    }
    return {
      ...toRefs(state),
      moment,
      upDateNowDate,
      addComponentDone,
      deleteComponent,
      submit,
      templateForm,
      release,
      updateGoodsList,
      templateIntro,
      changeTemplate,
      onCloseDrawer,
      releaseConfirm,
      getContainer,
      noRemind,
      handleSaveTemplate,
      deleteTemplate,
      openModal,
      releaseFormRef,
    }
  },
}
</script>
<style lang="less" scoped>
.main-page {
  background: #f8f8f8;
  min-width: 1000px;
  display: flex;
  height: calc(100vh - 136px);
  .page-left {
    height: 100%;
    overflow: auto;
    background: #fff;
    box-shadow: 3px 0 8px -4px rgba(0, 0, 0, 0.3);
    width: 260px;
    flex-shrink: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    position: relative;
    padding-bottom: 68px;
    .template-list {
      background: #fff;
      border: none;
      .template-item {
        text-indent: 24px;
        color: #333;
        margin-bottom: 12px;
        cursor: pointer;
        &:hover {
          color: #1890ff;
        }
        &.active {
          color: #1890ff;
        }
      }
    }
    .component-list {
      background: #fff;
      border: none;
      ::v-deep .ant-collapse-content-box {
        display: flex;
        align-items: center;
      }
      .c-add-img {
        background-image: url('../../assets/decorateIcon/add-img.png');
      }
      .c-menu-list {
        background-image: url('../../assets/decorateIcon/menu-img.png');
      }
      .c-empty-line {
        background-image: url('../../assets/decorateIcon/empty-img.png');
      }
      .c-goods-list {
        background-image: url('../../assets/decorateIcon/goods-img.png');
      }
      .c-text-title {
        background-image: url('../../assets/decorateIcon/text-img.png');
      }
      .component-item {
        padding-top: 36px;
        box-sizing: border-box;
        width: 104px;
        height: 76px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #fff;
        background-repeat: no-repeat;
        background-position: center top;
        background-size: 40px auto;
        cursor: move;
        color: #000;
        margin-bottom: 12px;
      }
      .component-item:hover {
        background-color: #1890ff;
        background-position: center -40px;
        color: #fff;
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .page-content {
    height: 100%;
    overflow: auto;
    flex: 1;
    flex-shrink: 0;
    padding: 12px 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    &::-webkit-scrollbar {
      display: none;
    }
    .phone-box {
      position: relative;
      width: 375px;
      min-height: 667px;
      box-shadow: 0px 0px 4px 2px rgba(0, 0, 0, 0.2);
      margin-left: 160px;
      background: #fff;
      .component-name {
        text-align: center;
        padding: 24px;
        background: #1890ff;
        color: #fff;
      }
      .line {
        position: absolute;
        top: 643px;
        left: -140px;
        line-height: 24px;
        border-bottom: 1px solid #ddd;
        color: #666;
        font-size: 12px;
        width: 140px;
      }
      .phone-status-bar {
        display: flex;
        align-items: center;
        justify-content: space-between;
        height: 24px;
        padding: 0 8px;
        font-size: 12px;
        font-weight: bold;
        line-height: 24px;
        .status-icon {
          font-weight: 900;
          font-size: 16px;
        }
        .status-left {
          display: flex;
          align-items: center;
          .dote-icon-list {
            display: flex;
            align-items: center;
            div {
              width: 6px;
              height: 6px;
              border-radius: 100%;
              background: #000;
              margin-right: 2px;
            }
          }
        }
        .status-right {
          display: flex;
          align-items: center;
          .status-icon {
            font-size: 28px;
            font-weight: 400;
          }
        }
      }
      .phone-title {
        font-size: 16px;
        text-align: center;
        position: relative;
        height: 44px;
        line-height: 44px;
        .status-icon {
          position: absolute;
          right: 18px;
          font-size: 24px;
          top: 10px;
        }
      }
      .component-box {
        position: relative;
        .line-tag {
          position: absolute;
          top: 0;
          right: -74px;
          line-height: 24px;
          border-bottom: 1px solid #ddd;
          color: #666;
          font-size: 12px;
          padding-left: 32px;
          padding-right: 8px;
        }
        .component {
          cursor: pointer;
          width: 375px;
          box-sizing: content-box;
        }
        .component:hover {
          border: 1px dashed #1890ff;
          margin-left: -1px;
        }
        .c-active {
          border: 2px solid #1890ff !important;
          margin-left: -2px !important;
        }
        .c-close-icon {
          position: absolute;
          top: -10px;
          right: -10px;
          font-size: 20px;
          background: #fff;
          border-radius: 50%;
          color: #1890ff;
          cursor: pointer;
          z-index: 20;
        }
      }
    }
  }
  .page-right {
    width: 400px;
    height: 100%;
    overflow: auto;
    background: #eee;
    box-shadow: -3px 0 8px -4px rgba(0, 0, 0, 0.3);
    flex-shrink: 0;
    scrollbar-width: none;
    -ms-overflow-style: none;
    .operation-title {
      background: #fff;
      font-size: 18px;
      font-weight: bold;
      line-height: 60px;
      border-bottom: 1px solid #ddd;
      padding: 0 16px;
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
}
.no-data-box {
  height: 100%;
  background: #fff;
  padding-top: 150px;
  box-sizing: border-box;
}
.btn-box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  background: #fff;
  z-index: 30;
  border-top: 1px solid #ddd;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 64px;
}
::v-deep .ant-drawer-body {
  padding: 0;
}
.no-design-data {
  background: #eee;
  min-height: 667px;
  padding-top: 100px;
  position: relative;
  box-sizing: border-box;
  .no-design-data-img {
    width: 100px;
    display: block;
    margin-left: 240px;
  }
  .no-design-data-text {
    width: 200px;
    position: absolute;
    top: 80px;
    box-shadow: 0 4px 8px #ddd;
    background: #fff;
    left: 32px;
    border-radius: 8px;
    padding: 16px 12px;
    .h3 {
      font-size: 16px;
      font-weight: 900;
      margin-bottom: 8px;
    }
    .p {
      font-size: 14px;
      color: #333;
      line-height: 24px;
    }
  }
}
.temp-btn-box {
  display: flex;
  justify-content: space-around;
  align-items: center;
  margin-top: 16px;
}
.flex-j-c {
  display: flex;
  justify-content: center;
}
</style>
